"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { analytics } from "lib/segment";

export default function AnalyticsNotound() {
  const pathname = usePathname();

  useEffect(() => {
    const data = {
      url: `${pathname}` || "Error fetching url",
    };

    analytics.track("Page Not Found", data);
  }, []);

  return null;
}
